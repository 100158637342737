import React, { Component } from 'react';
import './home.css';
import { Icon } from 'semantic-ui-react';

class Home extends Component {
  render() {
    return (
      <div className={'m-auto w-95 t-center'}>
        <h2 className={'mt-2'}>Play Ball!</h2>
        <h4>New for Opening Day 2022:</h4>
        <div>
          <Icon name={'checkmark'} /> Hit Types! Ground balls, fly balls, pop ups and line drives
        </div>
        <div>
          <Icon name={'checkmark'} /> Universal DH
        </div>
        <div>
          <Icon name={'checkmark'} /> Runner starts on second base in extra innings
        </div>
        <div>
          <Icon name={'checkmark'} /> Updated 2021 ballpark effects
        </div>
      </div>
    );
  }
}

export default Home;
