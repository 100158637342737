import React from 'react';

// eslint-disable-next-line unicorn/no-null
const FirebaseContext = React.createContext(null);

export const withFirebase = Component => properties => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...properties} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
