import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import Statistic from 'semantic-ui-react/dist/commonjs/views/Statistic';

class BinsTable extends Component {
  bins = data => {
    return data.map(({ label, wins, losses, units }) => {
      return (
        <Table.Row key={label}>
          <Table.Cell>{label}</Table.Cell>
          <Table.Cell>{`${wins}`}</Table.Cell>
          <Table.Cell>{`${losses}`}</Table.Cell>
          <Table.Cell>{this.percentage(wins, losses).toFixed(2)}%</Table.Cell>
          <Table.Cell>
            <Statistic className={this.setColor(units)}>{units.toFixed(2)}</Statistic>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  setColor = number => (number > 0 ? 'green' : 'red');

  percentage = (w, l) => (Number.parseFloat(w + l) > 0 ? (w / Number.parseFloat(w + l)) * 100 : 0);

  render() {
    const { data, header } = this.props;

    // eslint-disable-next-line unicorn/no-null
    if (data.length <= 0) return null;

    return (
      <Table className="Team-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={5}>{header}</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Bin</Table.HeaderCell>
            <Table.HeaderCell>Wins</Table.HeaderCell>
            <Table.HeaderCell>Losses</Table.HeaderCell>
            <Table.HeaderCell>Percentage</Table.HeaderCell>
            <Table.HeaderCell>Result (Units)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.bins(data)}</Table.Body>
      </Table>
    );
  }
}

export default BinsTable;
