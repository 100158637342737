import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { withFirebase } from '../Firebase';

const LogoutButton = ({ firebase, authUser }) => {
  // eslint-disable-next-line unicorn/no-null
  if (!authUser) return null;

  return (
    <Menu.Item>
      <span onClick={firebase.doSignOut}>
        <Icon name={'sign out'} className={'mr-1'} /> Log out
      </span>
    </Menu.Item>
  );
};

export default withFirebase(LogoutButton);
