import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// const config = {
//   apiKey: "AIzaSyAuGym08-WnFhj948QQOLwY7zdkdhptvZg",
//   authDomain: "baseballsim-abc68.firebaseapp.com",
//   databaseURL: "https://baseballsim-abc68.firebaseio.com",
//   projectId: "baseballsim-abc68",
//   storageBucket: "baseballsim-abc68.appspot.com",
//   messagingSenderId: "1064803521921"
// };

const config = {
  apiKey: "AIzaSyAuGym08-WnFhj948QQOLwY7zdkdhptvZg",
  authDomain: "baseballsim-abc68.firebaseapp.com",
  databaseURL: "https://baseballsim-abc68.firebaseio.com",
  projectId: "baseballsim-abc68",
  storageBucket: "baseballsim-abc68.appspot.com",
  messagingSenderId: "1064803521921",
  appId: "1:1064803521921:web:84c737e185321b6c238480"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.firestore = app.firestore();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();
}

export default Firebase;