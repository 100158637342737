import React from 'react';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(properties) {
      super(properties);

      this.state = {
        authUser: undefined,
      };
    }

    componentDidMount() {
      const { firebase } = this.props;

      this.listener = firebase.auth.onAuthStateChanged(authUser => {
        // eslint-disable-next-line unicorn/no-null
        authUser ? this.setState({ authUser }) : this.setState({ authUser: null });
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
