import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class TeamResultsTable extends Component {
  recordRow = (id, abbreviation, record, description) => {
    return (
      <Table.Row>
        <Table.Cell>
          <a href={`/team/${id}`}>{abbreviation}</a>
        </Table.Cell>
        <Table.Cell>{`(${description}) ${record.wins}-${record.losses}`}</Table.Cell>
      </Table.Row>
    );
  };

  calcRecord = (results, criteria1, criteria2) =>
    // eslint-disable-next-line unicorn/no-reduce
    results.reduce(
      (accumulator, value) => {
        return value[criteria1] === 1 && value[criteria2] === 1
          ? { wins: accumulator.wins + value.win, losses: accumulator.losses + value.loss }
          : accumulator;
      },
      { wins: 0, losses: 0 }
    );

  render() {
    const {
      homeId,
      awayId,
      homeAbbreviation,
      awayAbbreviation,
      homeResults,
      awayResults,
      favorite,
    } = this.props;

    const homeRecords = this.calcRecord(
      homeResults,
      'home',
      favorite === 'home' ? 'favorite' : 'dog'
    );

    const awayRecords = this.calcRecord(
      awayResults,
      'away',
      favorite === 'away' ? 'favorite' : 'dog'
    );

    return (
      <Table className="Odds-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2}>Wager Results (YTD)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.recordRow(
            awayId,
            awayAbbreviation,
            awayRecords,
            favorite === 'away' ? 'Road Favorite' : 'Road Dog'
          )}
          {this.recordRow(
            homeId,
            homeAbbreviation,
            homeRecords,
            favorite === 'home' ? 'Home Favorite' : 'Home Dog'
          )}
        </Table.Body>
      </Table>
    );
  }
}

TeamResultsTable.propTypes = {
  awayId: PropTypes.number.isRequired,
  homeId: PropTypes.number.isRequired,
  awayAbbreviation: PropTypes.string.isRequired,
  homeAbbreviation: PropTypes.string.isRequired,
  homeResults: PropTypes.array.isRequired,
  awayResults: PropTypes.array.isRequired,
  favorite: PropTypes.string.isRequired,
};

export default TeamResultsTable;
