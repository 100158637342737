import React, { Component } from 'react';
import { fetchTeams } from '../utilities/api';
import { Table } from 'semantic-ui-react';
import './Team/team.css';

class Teams extends Component {
  state = {
    teams: [],
  };

  componentDidMount() {
    fetchTeams().then(teams => this.setState({ teams }));
  }

  listTeams = () =>
    this.state.teams.map(team => {
      return (
        <Table.Row>
          <Table.Cell>{team.id}</Table.Cell>
          <Table.Cell>
            <a href={'/team/' + team.id}>{team.city}</a>
          </Table.Cell>
          <Table.Cell>
            <a href={'/team/' + team.id}>{team.mascot}</a>
          </Table.Cell>
          <Table.Cell>{team.abbreviation}</Table.Cell>
          <Table.Cell>{team.baseball_press_abbreviation}</Table.Cell>
        </Table.Row>
      );
    });

  render() {
    return (
      <Table striped textAlign="center" className="Team-Table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="Team-Header" colSpan={5}>
              MLB Teams
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>Mascot</Table.HeaderCell>
            <Table.HeaderCell>Abbreviation</Table.HeaderCell>
            <Table.HeaderCell>BBP Abbreviation</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.listTeams()}</Table.Body>
      </Table>
    );
  }
}

export default Teams;
