const root_url = 'http://45.79.64.76/api/v2';

const retro_url = '/retrosheet/';

const fetchUrl = async (url, baseUrl = root_url) => {
  try {
    const response = await fetch(baseUrl + url);
    const responseJson = await response.json();

    return responseJson.length === 1 ? responseJson[0] : responseJson;
  } catch (error) {
    console.error(error);
    throw Error;
  }
};

const fetchOne = async url =>
  fetchUrl(url).then(response => (response.data ? response.data[0] : undefined));

const fetchMany = (url, baseUrl = root_url) =>
  fetchUrl(url, baseUrl).then(response => (response ? response.data : []));

const fetchActualResults = () => fetchMany('/actual_results');

const fetchBooks = () => fetchMany('/book');

const fetchCurrentStatistics = playerId =>
  fetchOne(`/current_batting_statistics/player/${playerId}`);

const fetchCurrentPitchingStatistics = playerId =>
  fetchOne(`/current_pitching_statistics/player/${playerId}`);

const fetchGames = id => (id ? fetchOne(`/game/${id}`) : fetchMany('/game/'));

const fetchGamesByTeam = teamId => fetchMany(`/game/team/${teamId}`);

const fetchGameByDate = date => fetchMany(`/game/date/${date}`);

const fetchGamesAfterDate = date => fetchMany(`/game/after/date/${date}`);

const fetchLineups = gameId => fetchOne(`/lineup/game/${gameId}`);

const fetchLiveResults = () => fetchMany('/live_results');

const fetchOddsByGameId = gameId => fetchMany(`/odd/game/${gameId}`);

const fetchOdds = () => fetchMany('/odd');

const fetchNewPlayers = id => fetchOne(`/players/${id}`);

const fetchMetrics = (date, endDate) => fetchMany(`/metrics/${date}?endDate=${endDate}`);

const fetchPositions = () => fetchMany('/position');

const fetchRetrosheetHitter = retroId => fetchOne(`${retro_url}batting/player/${retroId}`);

const fetchRetrosheetPitcher = retroId => fetchOne(`${retro_url}pitching/player/${retroId}`);

const fetchSimulationResultByGameId = gameId => fetchOne(`/simulation_results/${gameId}`);

const fetchSimulationResults = () => fetchMany('/simulation_results/all/distinct');

const fetchTeam = id => fetchOne(`/team/${id}`);

const fetchTeams = () => fetchMany('/team');

const fetchWagers = () => fetchMany('/wager');

const fetchMetricsByTeam = (teamId, startDate, endDate) =>
  fetchMany(`/metrics/team/${teamId}/startDate/${startDate}?endDate=${endDate}`);

const fetchWagerByGame = gameId => fetchOne(`/wager/game/${gameId}`);

const fetchWagersAfterDate = date => fetchMany(`/wager/after/${date}`);

const fetchWagersAfterDateBeforeDate = (startDate, endDate) =>
  fetchMany(`/wager/after/${startDate}/before/${endDate}`);

const fetchWagersByDate = date => fetchMany(`/wager/date/${date}`);

const fetchRegressionResults = (baseUrl, version = 'v1') =>
  fetchMany(`/api/${version}/simulation_results`, baseUrl);

const fetchGamesByYear = year => fetchMany(`/game/year/${year}`);

const fetchSimulationProbabilitiesByGameId = gameId =>
  fetchMany(`/simulation_probabilities/${gameId}`);

export {
  fetchActualResults,
  fetchBooks,
  fetchCurrentStatistics,
  fetchCurrentPitchingStatistics,
  fetchGames,
  fetchGameByDate,
  fetchGamesByTeam,
  fetchGamesAfterDate,
  fetchLineups,
  fetchLiveResults,
  fetchOddsByGameId,
  fetchOdds,
  fetchMetrics,
  fetchNewPlayers,
  fetchPositions,
  fetchRetrosheetHitter,
  fetchRetrosheetPitcher,
  fetchSimulationResults,
  fetchSimulationResultByGameId,
  fetchTeam,
  fetchTeams,
  fetchWagers,
  fetchWagerByGame,
  fetchWagersAfterDate,
  fetchWagersByDate,
  fetchRegressionResults,
  fetchWagersAfterDateBeforeDate,
  fetchGamesByYear,
  fetchMetricsByTeam,
  fetchSimulationProbabilitiesByGameId,
  root_url,
  retro_url,
};
