import React, { Component } from 'react';
import { Dropdown, Header, Icon, Table } from 'semantic-ui-react';
import { fetchMetrics } from '../../utilities/api';
import './metrics.css';
import { withAuthorization } from '../Session';
import BinsTable from '../bins-table';

class Metrics extends Component {
  state = {
    lineBins: [
      {
        label: '< -250',
        min: -999,
        max: -250,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '-250 : -200',
        min: -250,
        max: -200,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '-200 : -150',
        min: -200,
        max: -150,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '-150 : -125',
        min: -150,
        max: -125,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '-125 : 100',
        min: -125,
        max: 100,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '100 : 125',
        min: 100,
        max: 125,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '125 : 150',
        min: 125,
        max: 150,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '150 : 200',
        min: 150,
        max: 200,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '200 : 250',
        min: 200,
        max: 250,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '> 250',
        min: 250,
        max: 999,
        wins: 0,
        losses: 0,
        units: 0,
      },
    ],
    allEvBins: [
      {
        label: '< 0',
        min: -999,
        max: 0,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '0-5',
        min: 0,
        max: 5,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '5-10',
        min: 5,
        max: 10,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '10-15',
        min: 10,
        max: 15,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '15-20',
        min: 15,
        max: 20,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '20-30',
        min: 20,
        max: 30,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '30-40',
        min: 30,
        max: 40,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '40-50',
        min: 40,
        max: 50,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '50-60',
        min: 50,
        max: 60,
        wins: 0,
        losses: 0,
        units: 0,
      },
      {
        label: '> 60',
        min: 60,
        max: 1000,
        wins: 0,
        losses: 0,
        units: 0,
      },
    ],
    last7dayTotals: {},
    last30dayTotals: {},
    seasonTotals: {},
    wageredOnBins: [],
    year: 2022,
  };

  componentDidMount = () => this.fetchData(this.state.year);

  fetchData = async year => {
    const date_string = `${year}0101`;
    const endDate = `${Number.parseInt(year) + 1}0101`;
    const { allEvBins } = this.state;

    fetchMetrics(date_string, endDate).then(metrics => {
      this.setState({
        seasonTotals: this.binBreakdown(metrics, -100, 200),
        wageredOnBins: allEvBins.map(bin => {
          const { wins, losses, netUnits: units } = this.binBreakdown(metrics, bin.min, bin.max);

          return {
            ...bin,
            wins,
            losses,
            units,
          };
        }),
      });
    });

    fetchMetrics(this.changeDate(-7), this.changeDate(0)).then(last7dayTotals =>
      this.setState({ last7dayTotals: this.binBreakdown(last7dayTotals, -100, 200) })
    );

    fetchMetrics(this.changeDate(-30), this.changeDate(0)).then(last30dayTotals =>
      this.setState({ last30dayTotals: this.binBreakdown(last30dayTotals, -100, 200) })
    );
  };

  yearChange = async (event, inputData) => {
    await this.setState({ year: inputData.value });
    await this.fetchData(inputData.value);
  };

  changeDate = direction => {
    const date = new Date();

    date.setDate(date.getDate() + direction);

    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);

    return String(date.getFullYear()) + month + day;
  };

  binBreakdown = (wagers, minEvent, maxEvent) =>
    wagers
      .filter(({ ev }) => ev >= minEvent && ev <= maxEvent)
      // eslint-disable-next-line unicorn/no-reduce
      .reduce(
        (accumulator, { loss, win, unit, outcome, push }) => {
          return {
            wins: accumulator.wins + win,
            losses: accumulator.losses + loss,
            pushes: accumulator.pushes + push,
            netUnits: accumulator.netUnits + Number(outcome) / Number(unit),
          };
        },
        { wins: 0, losses: 0, pushes: 0, netUnits: 0 }
      );

  generateStatsTable = (header, totals) => {
    // eslint-disable-next-line unicorn/no-null
    if (Object.keys(totals).length <= 0) return null;

    const { wins = 0, losses = 0, netUnits = 0 } = totals;

    return (
      <Table className="Metrics-AccountsTable" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>{header}</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Wins</Table.HeaderCell>
            <Table.HeaderCell>Losses</Table.HeaderCell>
            <Table.HeaderCell>Percentage</Table.HeaderCell>
            <Table.HeaderCell>Result (Units)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{wins}</Table.Cell>
            <Table.Cell>{losses}</Table.Cell>
            <Table.Cell>{wins && losses ? this.percentage(wins, losses) : 0}%</Table.Cell>
            <Table.Cell>{netUnits ? netUnits.toFixed(2) : 0}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  percentage = (wins, losses) =>
    (Number.parseFloat(wins + losses) > 0
      ? (wins / Number.parseFloat(wins + losses)) * 100
      : 0
    ).toFixed(2);

  render() {
    const {
      authUser: { email },
    } = this.props;

    if (!['mppavese@gmail.com', 'kalikgod@gmail.com'].includes(email)) {
      return <div>Coming soon...</div>;
    }
    const { last7dayTotals, last30dayTotals, seasonTotals, wageredOnBins, year } = this.state;

    const currentYear = new Date().getFullYear();

    return (
      <div>
        <Header as="h4" className={'w-50 m-auto t-center'}>
          <Header.Content className={'t-center w-100 mt-2'}>
            <Icon name={'line graph'} />
            Season:{' '}
            <Dropdown
              options={[
                { key: 0, text: 2022, value: 2022 },
                { key: 1, text: 2021, value: 2021 },
                { key: 2, text: 2020, value: 2020 },
                { key: 3, text: 2019, value: 2019 },
                { key: 4, text: 2018, value: 2018 },
                { key: 5, text: 2017, value: 2017 },
                { key: 6, text: 2016, value: 2016 },
              ]}
              name={'year'}
              inline
              value={year}
              onChange={this.yearChange}
              className={'mt-2'}
            />
          </Header.Content>
        </Header>
        {this.generateStatsTable('Entire Season', seasonTotals)}
        {currentYear === year && this.generateStatsTable('Last 7 Days', last7dayTotals)}
        {currentYear === year && this.generateStatsTable('Last 30 Days', last30dayTotals)}

        <BinsTable data={wageredOnBins} header={'EV Breakdown (Wagered On)'} />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Metrics);
