import { Checkbox, Dropdown, Icon, Label } from 'semantic-ui-react';

export default function DataTableFilters({ callback, filters, options, name }) {
  const formatFilterOptions = (options, dataKey) =>
    [...new Set(options.map(option => option[dataKey]?.filterKey || option[dataKey]))].map(
      (option, key) => ({
        key,
        text: (
          <Checkbox
            label={option}
            checked={filters.hasOwnProperty(dataKey) ? filters[dataKey].includes(option) : false}
            onClick={handleFilterClick}
            value={option}
            name={dataKey}
          />
        ),
        value: option,
      })
    );

  const handleFilterClick = (_, { value, name }) => {
    let { [name]: existingFilters = [], ...rest } = filters;

    if (!existingFilters.includes(value)) {
      existingFilters.push(value);
    } else {
      existingFilters = existingFilters.filter(filter => {
        return filter !== value;
      });
    }

    callback(previousState => {
      return existingFilters.length > 0
        ? { ...previousState, [name]: existingFilters }
        : { ...rest };
    });
  };

  return (
    <Dropdown
      trigger={<Icon name={'filter'} />}
      options={formatFilterOptions(options, name)}
      compact
      name={name}
      floating
      value={''}
      closeOnChange={false}
      selectOnBlur={false}
      scrolling={true}
      header={
        <Label
          icon={'remove'}
          content={'Clear All'}
          className={'w-100 t-center pointer'}
          size={'large'}
          onClick={() => callback(({ [name]: _, ...rest }) => ({ ...rest }))}
        />
      }
    />
  );
}
