import React from 'react';
import { Label } from 'semantic-ui-react';

export default function DataTableLabels({ labelList, callback }) {
  const removeLabel = (labelKey, value) => {
    const modifiedFilters = labelList[labelKey].filter(previousFilter => previousFilter !== value);

    const newFilterEntry = modifiedFilters.length > 0 ? { [labelKey]: modifiedFilters } : {};

    callback(({ [labelKey]: _, ...rest }) => ({
      ...rest,
      ...newFilterEntry,
    }));
  };

  return (
    <Label.Group>
      {Object.keys(labelList).map(labelKey =>
        labelList[labelKey].map((value, key) => (
          <Label
            className={'m-1 pointer shadow-pop'}
            key={key}
            onClick={() => removeLabel(labelKey, value)}
            onRemove={event => {
              event.stopPropagation();
              removeLabel(labelKey, value);
            }}
            size={'large'}
            content={value}
          />
        ))
      )}
    </Label.Group>
  );
}
