import React, { Component } from 'react';
import { fetchTeams, fetchWagersByDate } from '../../utilities/api';
import { Icon, Popup, Statistic, Table } from 'semantic-ui-react';
import './wager.css';
import { withAuthorization } from '../Session';
import Calendar from '../calendar';

class Wagers extends Component {
  state = {
    games: [],
    standings: [],
    teams: [],
    wagers: [],
    liveResults: [],
    wagerDate: undefined,
  };

  componentDidMount() {
    let { date = this.dateToString(new Date()) } = this.props.match.params;

    this.fetchData(date).catch(() => {});
  }

  fetchData = async date => {
    this.setState({
      wagerDate: date,
      interval: setInterval(this.fetchResults, 10000),
      wagers: await fetchWagersByDate(date),
      teams: await fetchTeams(),
    });
  };

  fetchResults = () =>
    fetchWagersByDate(this.state.wagerDate).then(wagers => this.setState({ wagers }));

  dateToString = date => {
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return String(date.getFullYear()) + month + day;
  };

  formatScore = (status, score) =>
    ['Pre-Game', 'Scheduled', 'Postponed'].includes(status) ? '' : score;

  formatInningState = (inning = '', inning_state = '', status = '') => {
    let inningString = inning_state;

    if (inning_state === 'Bottom') {
      inningString = 'Bot';
    } else if (inning_state === 'Middle') {
      inningString = 'Mid';
    }

    inningString += ' ' + inning;

    if (status === 'Final' || status === 'Game Over') {
      inningString = 'Final';
    } else if (status === 'Pre-Game' || status === 'Scheduled') {
      inningString = 'Pre-Game';
    } else if (status === 'Postponed') {
      inningString = 'PPD';
    }

    return inningString;
  };

  calculateRisk = (unit, amount) => amount / unit;

  addPlusSign = value => (value > 0 ? '+' + value : value);

  outcomeTotal = () =>
    this.state.wagers
      .map(wager => wager.outcome / wager.unit)
      // eslint-disable-next-line unicorn/no-reduce
      .reduce((total, value) => Number.parseFloat(total) + Number.parseFloat(value))
      .toFixed(2);

  riskTotal = () =>
    this.state.wagers
      .map(wager => wager.amount / wager.unit)
      // eslint-disable-next-line unicorn/no-reduce
      .reduce((total, value) => Number.parseFloat(total) + Number.parseFloat(value))
      .toFixed(2);

  listWagers = () =>
    this.state.wagers.map(wager => {
      const { teams } = this.state;
      const {
        id,
        game_id,
        game_of_day,
        line,
        outcome,
        unit,
        team: { id: teamId, abbreviation },
        amount,
        game = {},
      } = wager;
      const { home_id, away_id, inning, inning_state, status, home_score = 0, away_score = 0 } = game;

      const { abbreviation: awayAbbrev } = teams.find(({ id }) => id === away_id);
      const { abbreviation: homeAbbrev } = teams.find(({ id }) => id === home_id);

      return (
        <Table.Row key={id}>
          <Table.Cell>
            <div className="LiveScore">
              <div className="Inning-Status">
                {this.formatInningState(inning, inning_state, status)}
              </div>
              <a href={'/simulation_results/' + game_id}>
                {awayAbbrev + ' ' + this.formatScore(status, away_score)}
                <br />
                {homeAbbrev + ' ' + this.formatScore(status, home_score)}
              </a>
            </div>
          </Table.Cell>
          <Table.Cell>
            {game_of_day === 1 && (
              <Popup
                trigger={<Icon name="lightning" color="yellow" />}
                content={'Game of the Day'}
              />
            )}
            <a href={`/team/${teamId}`}>{abbreviation}</a> ({this.addPlusSign(line)})
          </Table.Cell>
          <Table.Cell>{this.calculateRisk(unit, amount).toFixed(2)}</Table.Cell>
          <Table.Cell>{outcome ? this.addPlusSign((outcome / unit).toFixed(2)) : '-'}</Table.Cell>
        </Table.Row>
      );
    });

  handleDropdownChange = (event, data) => this.fetchData(data.value).catch(() => {});

  determineColor = total => {
    let color = Number.parseFloat(total) > 0 ? 'green' : 'black';
    color = Number.parseFloat(total) < 0 ? 'red' : color;
    return color;
  };

  render() {
    const { wagerDate, wagers } = this.state;

    const color = wagers.length > 0 ? this.determineColor(this.outcomeTotal()) : 'black';
    return (
      <div>
        <Calendar date={wagerDate} onChange={this.handleDropdownChange} />
        <Table
          striped
          textAlign="center"
          unstackable
          className="Wager-Table"
          size="small"
          columns={4}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="Team-Header" colSpan={4}>
                <Icon name="dollar" />
                Today's Card
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Bet</Table.HeaderCell>
              <Table.HeaderCell>Risk (Units)</Table.HeaderCell>
              <Table.HeaderCell>Result (Units)</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {wagers.length > 0 && (
            <Table.Body>
              {this.listWagers()}
              <Table.Row>
                <Table.Cell>
                  <strong>Total</strong>
                </Table.Cell>
                <Table.Cell />
                <Table.Cell>
                  <strong>{this.riskTotal()}</strong>
                </Table.Cell>
                <Table.Cell>
                  <Statistic size={'mini'} className={color}>
                    <strong>{this.addPlusSign(this.outcomeTotal())}</strong>
                  </Statistic>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
          {wagers.length === 0 && (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan={5}>No wagers have been placed today</Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Wagers);
