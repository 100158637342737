import { fetchSimulationProbabilitiesByGameId } from '../../utilities/api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from '../data-table';
import { Loader } from 'semantic-ui-react';

const formatProbs = (probs, hit_type, vs) => {
  const probabilities = JSON.parse(probs);
  const newProbs = {};

  console.log({ probabilities });

  Object.keys(probabilities).map(prob => {
    if (['hits', 'singles', 'doubles', 'triples', 'homers', 'outs'].includes(prob)) return false;

    const pattern = new RegExp(`${hit_type}+`);
    if (!pattern.test(prob)) return false;

    let prefix = prob;
    let suffix = '';
    // if (/ground_ball+/.test(prob)) {
    //   prefix = 'gb';
    // } else if (/fly_ball+/.test(prob)) {
    //   prefix = 'fb';
    // } else if (/pop_up+/.test(prob)) {
    //   prefix = 'pu';
    // } else if (/line_drive+/.test(prob)) {
    //   prefix = 'ld';
    // }

    if (/singles+/.test(prob)) {
      suffix = '1B';
    } else if (/doubles+/.test(prob)) {
      suffix = '2b';
    } else if (/triples+/.test(prob)) {
      suffix = '3b';
    } else if (/homers+/.test(prob)) {
      suffix = 'Homers';
    } else if (/_outs+/.test(prob)) {
      suffix = 'Outs';
    } else if (/hits+/.test(prob)) {
      suffix = 'Hits';
    } else {
      suffix = 'Events';
    }

    newProbs[`${suffix}_vs_${vs}`] = probabilities[prob].toFixed(2);
  });

  return newProbs;
};

export default function SimulationProbabilities() {
  const [probabilities, setProbabilities] = useState([]);
  const { gameId } = useParams();

  useEffect(() => {
    fetchSimulationProbabilitiesByGameId(gameId).then(results => setProbabilities(results));
  }, [gameId]);

  if (probabilities.length === 0)
    return <Loader active={true} size={'massive'} content={'Loading probabilities'} />;

  console.log({ probabilities });

  return (
    <div className={'m-4'}>
      <DataTable
        header={'Ground Balls'}
        data={probabilities.map(
          ({
            team_id: teamId,
            historic_statistics: historicalStats = '{}',
            probabilities_versus_rp: probsVsRp = '{}',
            probabilities_versus_sp: probsVsSp = '{}',
            player: { id, first_name: firstName, last_name: lastName },
            team: { abbreviation },
          }) => ({
            player: `${lastName}, ${firstName} (${abbreviation})`,
            ...formatProbs(probsVsSp, 'ground_ball', 'sp'),
            ...formatProbs(probsVsRp, 'ground_ball', 'rp'),
          })
        )}
      />
      <DataTable
        header={'Fly Balls'}
        data={probabilities.map(
          ({
            team_id: teamId,
            historic_statistics: historicalStats = '{}',
            probabilities_versus_rp: probsVsRp = '{}',
            probabilities_versus_sp: probsVsSp = '{}',
            player: { id, first_name: firstName, last_name: lastName },
            team: { abbreviation },
          }) => ({
            player: `${lastName}, ${firstName} (${abbreviation})`,
            ...formatProbs(probsVsSp, 'fly_ball', 'sp'),
            ...formatProbs(probsVsRp, 'fly_ball', 'rp'),
          })
        )}
      />
      <DataTable
        header={'Line Drives'}
        data={probabilities.map(
          ({
            team_id: teamId,
            historic_statistics: historicalStats = '{}',
            probabilities_versus_rp: probsVsRp = '{}',
            probabilities_versus_sp: probsVsSp = '{}',
            player: { id, first_name: firstName, last_name: lastName },
            team: { abbreviation },
          }) => ({
            player: `${lastName}, ${firstName} (${abbreviation})`,
            ...formatProbs(probsVsSp, 'line_drive', 'sp'),
            ...formatProbs(probsVsRp, 'line_drive', 'rp'),
          })
        )}
      />
      <DataTable
        header={'Pop Up'}
        data={probabilities.map(
          ({
            team_id: teamId,
            historic_statistics: historicalStats = '{}',
            probabilities_versus_rp: probsVsRp = '{}',
            probabilities_versus_sp: probsVsSp = '{}',
            player: { id, first_name: firstName, last_name: lastName },
            team: { abbreviation },
          }) => ({
            player: `${lastName}, ${firstName} (${abbreviation})`,
            ...formatProbs(probsVsSp, 'pop_up', 'sp'),
            ...formatProbs(probsVsRp, 'pop_up', 'rp'),
          })
        )}
      />
    </div>
  );
}
