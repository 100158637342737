import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class StatsTable extends Component {
  type = 'hitter';

  stat_categories = {
    hitter: {
      ab: "at_bats",
      pa: "plate_appearances",
      h: "hits",
      "2b": "doubles",
      "3b": "triples",
      hr: "homers",
      bb: "walks",
      ibb: "intentional_walks",
      r: "runs",
      sb: "stolen_bases",
      cs: "caught_stealing",
      so: "strikeouts"
    },
    pitcher: {
      g: "games_played",
      gs: "games_started",
      ip: "innings",
      bf: "batters_faced",
      h: "hits",
      "2b": "doubles",
      "3b": "triples",
      hr: "homers",
      bb: "walks",
      ibb: "intentional_walks",
      sb: "stolen_bases",
      cs: "caught_stealing",
      so: "strikeouts"
    }
  }

  componentDidMount = () => {
    const { pitcher } = this.props;

    if (pitcher === true) {
      this.type = 'pitcher';
    }
  }

  createBody = () => Object.keys(this.stat_categories[this.type]).map(stat => {
    return <Table.Cell>{this.props.stats[this.stat_categories[this.type][stat]] ? this.props.stats[this.stat_categories[this.type][stat]] : '-'}</Table.Cell>
  })

  createHeader = () => Object.keys(this.stat_categories[this.type]).map(stat => {
    return <Table.HeaderCell>{stat}</Table.HeaderCell>
  })

  render() {
    const { title } = this.props;

    return (
      <Table className="Player-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={Object.keys(this.stat_categories[this.type]).length}>
              { title }
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            {this.createHeader()}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {this.createBody()}
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }
}

export default StatsTable;