import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Message,
  Modal,
  Table,
} from 'semantic-ui-react';
import './simulationResults.css';
import {
  fetchNewPlayers,
  fetchSimulationResultByGameId,
  fetchBooks,
  fetchWagerByGame,
  fetchMetricsByTeam,
} from '../../utilities/api';
import { withAuthorization } from '../Session';
import ExpectedValueTable from './expected-value-table';
import LineupsTable from './lineups-table';
import TeamResultsTable from './team-results-table';
import { Link } from 'react-router-dom';

class SimulationResults extends Component {
  state = {
    awayTeam: {},
    homeTeam: {},
    result: undefined,
    odds: [],
    replacementHitters: [],
    replacementPitchers: [],
    homeLineup: [],
    awayLineup: [],
    lineups: { home: [], away: [] },
    books: undefined,
    homeSp: {},
    awaySp: {},
    modalOpen: false,
    modalContents: {},
    betLine: 0,
    status: undefined,
    gameOfDay: false,
    unitSize: 1,
    homeResults: [],
    awayResults: [],
    ticketCost: 0,
    toWin: 0,
  };

  componentDidMount() {
    const { game_id } = this.props.match.params;

    fetchWagerByGame(game_id).then(wager => this.setState({ wager }));
    fetchBooks().then(books => this.setState({ books }));
    fetchSimulationResultByGameId(game_id).then(result => {
      const {
        game: {
          game_date_time: gameDateTime,
          away_team: awayTeam,
          home_team: homeTeam,
          odds,
          lineup: {
            replacement_hitters: replacementHitters,
            replacement_pitchers: replacementPitchers,
            home: homeLineup = {},
            away: awayLineup = {},
            home_sp_id: homeSpId,
            away_sp_id: awaySpId,
          },
        },
      } = result;

      this.setState({
        result,
        awayTeam,
        homeTeam,
        odds,
        replacementHitters,
        replacementPitchers,
      });

      if (!result) return;

      this.getPlayers('away', awayLineup);
      this.getPlayers('home', homeLineup);

      fetchNewPlayers(homeSpId).then(homeSp => this.setState({ homeSp }));
      fetchNewPlayers(awaySpId).then(awaySp => this.setState({ awaySp }));

      fetchMetricsByTeam(
        homeTeam.id,
        '20220101',
        gameDateTime.slice(0, 10).replace('-', '')
      ).then(homeResults => this.setState({ homeResults }));

      fetchMetricsByTeam(
        awayTeam.id,
        '20220101',
        gameDateTime.slice(0, 10).replace('-', '')
      ).then(awayResults => this.setState({ awayResults }));
    });
  }

  getPlayers = async (side, team) =>
    await Promise.all(
      Object.keys(team).map(async battingSpot => {
        if (team[battingSpot] === 100001) {
          return { id: 100001, first_name: 'Replacement', last_name: 'Player', battingSpot };
        }

        return await fetchNewPlayers(team[battingSpot]).then(player => ({
          ...player,
          battingSpot: Number.parseInt(battingSpot, 10),
        }));
      })
    ).then(players => this.setState({ [`${side}Lineup`]: players }));

  getBook = id => this.state.books.filter(book => book.id === id);

  determineColor = event_ => (event_ > 5 ? 'green' : 'black');

  renderWagerTable = wager => {
    const { amount, game_of_day, line, unit, team_id, outcome } = wager;
    const { awayTeam, homeTeam } = this.state;

    const team = awayTeam.id === team_id ? awayTeam.abbreviation : homeTeam.abbreviation;

    return (
      <Table className="Teams-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>
              {game_of_day === 1 && <Icon name={'lightning'} color={'yellow'} />}
              {outcome > 0 && <Icon name={'check circle'} color={'green'} />}
              {outcome < 0 && <Icon name={'times circle'} color={'red'} />}
              {outcome === 0 && <Icon name={'minus circle'} color={'blue'} />}
              {!outcome && <Icon name={'clock'} color={'blue'} />}
              Wager
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Team</Table.HeaderCell>
            <Table.HeaderCell>Line</Table.HeaderCell>
            <Table.HeaderCell>Risk (Units)</Table.HeaderCell>
            <Table.HeaderCell>Result (Units)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <a href={`/team/${team_id}`}>{team}</a>
            </Table.Cell>
            <Table.Cell>{line > 0 ? `+${line}` : line}</Table.Cell>
            <Table.Cell>{(amount / unit).toFixed(2)}</Table.Cell>
            <Table.Cell>{(outcome / unit).toFixed(2)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  openModal = (team, line, bookId) => {
    const { unitSize } = this.state;
    const unit = 50;
    const ticketCost = line > 0 ? unit * unitSize : ((-1 * line) / 100) * unit * unitSize;

    const toWin = ticketCost > unit ? unit : (unit * line) / 100;

    this.setState({
      modalOpen: true,
      modalContents: { team, line, bookId },
      betLine: line,
      ticketCost,
      toWin,
    });
  };

  closeModal = () => this.setState({ modalOpen: false });

  updateLine = (event, data) => {
    const unit = 50;

    const { unitSize } = this.state;

    const ticketCost =
      data.value > 0 ? unit * unitSize : ((-1 * data.value) / 100) * unit * unitSize;

    const toWin = data.value < 0 ? unit * unitSize : (unit * unitSize * data.value) / 100;

    this.setState({ betLine: data.value, ticketCost, toWin });
  };

  updateUnitSize = (event, data) => {
    const unit = 50;
    const unitSize = data.value;
    const { betLine } = this.state;

    const ticketCost = betLine > 0 ? unit * unitSize : ((-1 * betLine) / 100) * unit * unitSize;

    const toWin = betLine < 0 ? unit * unitSize : (unit * unitSize * betLine) / 100;

    this.setState({ unitSize, ticketCost, toWin });
  };

  toggleGameOfDay = () => this.setState({ gameOfDay: !this.state.gameOfDay });

  bettingLabel = (team, line, book_id) => {
    const { abbreviation } = team;
    const { wager } = this.state;
    const {
      authUser: { email },
    } = this.props;

    const click = () => {
      if (wager || !['mppavese@gmail.com', 'kalikgod@gmail.com'].includes(email)) return;

      this.openModal(team, line, book_id);
    };

    return (
      <Label onClick={click}>
        {abbreviation}
        {line && line > 0 ? ` +${line}` : ` ${line}`}
      </Label>
    );
  };

  listOdds = () =>
    this.state.odds.map(odds => {
      const { away_line: awayLine, book_id: bookId, home_line: homeLine, total } = odds;
      const { awayTeam, books, homeTeam } = this.state;

      return (
        <Table.Row key={bookId}>
          <Table.Cell className={'a-left'}>{books && this.getBook(bookId)[0].name}</Table.Cell>
          <Table.Cell>{this.bettingLabel(awayTeam, awayLine, bookId)}</Table.Cell>
          <Table.Cell>{this.bettingLabel(homeTeam, homeLine, bookId)}</Table.Cell>
          <Table.Cell>{total}</Table.Cell>
        </Table.Row>
      );
    });

  submitWager = () => {
    const { game_id } = this.props.match.params;
    const {
      game: { game_date_time },
    } = this.state.result;
    const unit = 50;

    const {
      modalContents: { bookId, team },
      betLine,
      gameOfDay,
      unitSize,
    } = this.state;

    const amount = betLine > 0 ? unit * unitSize : ((-1 * betLine) / 100) * unit * unitSize;

    const data = {
      game_id,
      game_date_time,
      team_id: team.id,
      line: betLine,
      book_id: bookId,
      amount,
      unit,
      game_of_day: gameOfDay ? 1 : 0,
    };

    fetch('http://45.79.64.76/api/v2/wager', {
      method: 'POST',
      body: JSON.stringify({
        game: data,
        token: '4kalikBoozers36',
      }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(({ error }) => {
        const status = Object.keys(error).length > 0 ? 'error' : 'success';

        window.scrollTo(0, 0);
        this.setState({ status, modalOpen: false });
      });
  };

  clearStatus = () => this.setState({ status: undefined });

  render() {
    if (!this.state.result) {
      return <div>No results found for this game id.</div>;
    }

    const { game_id } = this.props.match.params;

    const {
      away_hits_per_game,
      away_runs_per_game,
      away_walks_per_game,
      away_win_percentage: awayWinPercentage,
      home_hits_per_game,
      home_runs_per_game,
      home_walks_per_game,
      home_win_percentage: homeWinPercentage,
      game: { game_date_time },
      number_of_sims: numberOfSims,
    } = this.state.result;

    const {
      awayLineup,
      awaySp,
      awayTeam,
      odds,
      homeLineup,
      homeSp,
      homeTeam,
      wager,
      modalOpen,
      modalContents,
      betLine,
      status,
      gameOfDay,
      unitSize,
      replacementHitters,
      replacementPitchers,
      homeResults,
      awayResults,
      ticketCost,
      toWin,
    } = this.state;

    const { abbreviation: awayAbbrev, city: awayCity, mascot: awayMascot } = awayTeam;
    const { abbreviation: homeAbbrev, city: homeCity, mascot: homeMascot } = homeTeam;

    const matchup = `${awayCity} ${awayMascot} @ ${homeCity} ${homeMascot}`;

    const { bookId, team } = modalContents;

    const bookName = bookId ? this.getBook(bookId)[0].name : '';
    const teamMascot = team ? team.mascot : '';

    return (
      <>
        {status && (
          <div className={'m-auto w-95'}>
            <Message
              content={
                status === 'success' ? 'Locked and loaded!' : 'An error occurred. Please try again'
              }
              color={status === 'success' ? 'green' : 'red'}
              onDismiss={this.clearStatus}
              className={'mt-2 w-100'}
            />
          </div>
        )}
        <Grid columns={2} container stackable>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2" className="SimulationResults-Header">
                <Icon name="newspaper outline" />
                <Header.Content>
                  {matchup}
                  <Header.Subheader>{game_date_time.slice(0, 10)}</Header.Subheader>
                </Header.Content>
              </Header>
              {wager && this.renderWagerTable(wager)}
              <Table className="Teams-Table" unstackable striped textAlign="center" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <a href={`/simulation_probabilities/${game_id}`}>
                        <Icon name={'flask'} size={'large'} />
                      </a>
                    </Table.HeaderCell>
                    <Table.HeaderCell>Win %</Table.HeaderCell>
                    <Table.HeaderCell>Walks / game</Table.HeaderCell>
                    <Table.HeaderCell>Hits / game</Table.HeaderCell>
                    <Table.HeaderCell>Runs / game</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <a href={`/team/${awayTeam.id}`}>{awayAbbrev}</a>
                    </Table.Cell>
                    <Table.Cell>{awayWinPercentage.toFixed(2)}%</Table.Cell>
                    <Table.Cell>{away_walks_per_game.toFixed(2)}</Table.Cell>
                    <Table.Cell>{away_hits_per_game.toFixed(2)}</Table.Cell>
                    <Table.Cell>{away_runs_per_game.toFixed(2)}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <a href={`/team/${homeTeam.id}`}>{homeAbbrev}</a>
                    </Table.Cell>
                    <Table.Cell>{homeWinPercentage.toFixed(2)}%</Table.Cell>
                    <Table.Cell>{home_walks_per_game.toFixed(2)}</Table.Cell>
                    <Table.Cell>{home_hits_per_game.toFixed(2)}</Table.Cell>
                    <Table.Cell>{home_runs_per_game.toFixed(2)}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <TeamResultsTable
                homeId={homeTeam.id}
                awayId={awayTeam.id}
                awayAbbreviation={awayAbbrev}
                homeAbbreviation={homeAbbrev}
                awayResults={awayResults}
                homeResults={homeResults}
                favorite={odds[0].home_line < 0 ? 'home' : 'away'}
              />
              <ExpectedValueTable
                awayAbbreviation={awayAbbrev}
                homeAbbreviation={homeAbbrev}
                homeOdds={Number.parseInt(odds[0].home_line, 10)}
                awayOdds={Number.parseInt(odds[0].away_line, 10)}
                homeWinPercentage={homeWinPercentage}
                awayWinPercentage={awayWinPercentage}
              />
            </Grid.Column>
            <Grid.Column>
              <Table className="Odds-Table" unstackable striped textAlign="center" size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={6}>Odds at time of sim</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>Sportsbook</Table.HeaderCell>
                    <Table.HeaderCell>Away line</Table.HeaderCell>
                    <Table.HeaderCell>Home line</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{this.listOdds()}</Table.Body>
              </Table>
              <LineupsTable
                awayAbbreviation={awayAbbrev}
                homeAbbreviation={homeAbbrev}
                awayLineup={awayLineup}
                homeLineup={homeLineup}
                awaySp={awaySp}
                homeSp={homeSp}
                replacementHitters={replacementHitters}
                replacementPitchers={replacementPitchers}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={modalOpen}
          onClose={this.closeModal}
          closeIcon={true}
          size="small"
          className={'w-90 m-auto'}
        >
          {/* <Header icon='money' content={`Bet ${teamAbbrev}`} />*/}
          {/*  <Modal.Content>*/}
          {/*    <div className={'mb-2'}>*/}
          {/*      <Input*/}
          {/*        label={bookName}*/}
          {/*        name='bet'*/}
          {/*        type={'number'}*/}
          {/*        className={'w-50'}*/}
          {/*        step={1}*/}
          {/*        value={betLine}*/}
          {/*        onChange={this.updateLine}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div className={'mb-2'}>*/}
          {/*      <Input*/}
          {/*        label={'Units'}*/}
          {/*        name='bet'*/}
          {/*        type={'number'}*/}
          {/*        className={'w-50'}*/}
          {/*        step={.1}*/}
          {/*        value={unitSize}*/}
          {/*        onChange={this.updateUnitSize}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div className={'mb-2'}>*/}
          {/*      <Checkbox checked={gameOfDay} onChange={this.toggleGameOfDay}/>*/}
          {/*      <Label content={'Game of the Day'} />*/}
          {/*    </div>*/}
          {/*    <Button content={'Save'} icon={'save'} onClick={this.submitWager}/>*/}
          {/*  </Modal.Content>*/}
          {/* </Modal>*/}
          <Header icon="money" content={`${bookName} Sportsbook`} />
          <h2 className={'ml-3'}>
            <div>STRAIGHT BETS</div>
            <div>1 BET(S) @ ${ticketCost.toFixed(2)}</div>
          </h2>
          <hr />
          <Modal.Content>
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <h4 className={'mb-2'}>MLB BASEBALL</h4>
                </Grid.Column>
                <Grid.Column />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>[101] - {teamMascot.toUpperCase()} ML</Grid.Column>
                <Grid.Column className={'t-center'}>
                  <Input
                    name="bet"
                    type={'number'}
                    className={'w-50 t-center'}
                    step={1}
                    value={betLine}
                    onChange={this.updateLine}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>UNITS</Grid.Column>
                <Grid.Column className={'t-center'}>
                  <Input
                    name="bet"
                    type={'number'}
                    className={'w-50 t-center'}
                    step={0.1}
                    value={unitSize}
                    onChange={this.updateUnitSize}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>GAME OF THE DAY</Grid.Column>
                <Grid.Column className={'t-center'}>
                  <Checkbox checked={gameOfDay} onChange={this.toggleGameOfDay} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <h3>
                    <div>TICKET COST</div>
                    <div>TO WIN: </div>
                    <div>TO COLLECT: </div>
                  </h3>
                </Grid.Column>
                <Grid.Column className={'t-center'}>
                  <h3>
                    <div>${ticketCost.toFixed(2)}</div>
                    <div>${toWin.toFixed(2)}</div>
                    <div>${(ticketCost + toWin).toFixed(2)}</div>
                  </h3>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column />
                <Grid.Column>
                  <Button content={'Accept'} icon={'barcode'} onClick={this.submitWager} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(SimulationResults);
