import React, { Component } from 'react';
import { Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import {
  fetchCurrentStatistics,
  fetchCurrentPitchingStatistics,
  fetchNewPlayers,
  fetchPositions,
  fetchRetrosheetHitter,
  fetchRetrosheetPitcher,
  fetchTeams,
  root_url,
  retro_url,
} from '../../utilities/api';
import './player.css';
import StatsTable from '../stats-table';
import { withAuthorization } from '../Session';

class Player extends Component {
  state = {
    retro_loading: true,
    retro_pitching_loading: true,
    current_hitting_loading: true,
    current_pitching_loading: true,
    player: {},
    player_ids: {},
    positions: [],
    retro_stats: {},
    retro_pitching_stats: {},
    current_hitting_stats: {},
    current_pitching_stats: {},
    teams: undefined,
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    fetchTeams().then(teams => this.setState({ teams }));
    fetchPositions().then(positions => this.setState({ positions }));
    fetchNewPlayers(id).then(player => {
      const { id, retrosheets_id } = player;
      this.setState({ player });

      fetchRetrosheetHitter(retrosheets_id).then(retro_stats =>
        this.setState({ retro_stats, retro_loading: false })
      );
      fetchRetrosheetPitcher(retrosheets_id).then(retro_pitching_stats =>
        this.setState({ retro_pitching_stats, retro_pitching_loading: false })
      );
      fetchCurrentStatistics(id).then(current_hitting_stats =>
        this.setState({
          current_hitting_stats: current_hitting_stats,
          current_hitting_loading: false,
        })
      );
      fetchCurrentPitchingStatistics(id).then(current_pitching_stats =>
        this.setState({
          current_pitching_stats: current_pitching_stats,
          current_pitching_loading: false,
        })
      );
    });
  }

  createRetrosheetLink = id => <a href={root_url + retro_url + '/batting/player/' + id}>{id}</a>;

  getPosition = position_id =>
    this.state.positions.map(position => {
      // eslint-disable-next-line unicorn/no-null
      return position.id === position_id ? position.position : null;
    });

  getTeam = team_id => this.state.teams.filter(team => team.id === team_id);

  fetchPlayerData = async player_id => {
    const player = await fetchNewPlayers(player_id);

    const { id, retrosheets_id } = player;

    fetchRetrosheetHitter(retrosheets_id).then(retro_stats => {
      this.setState({ retro_stats, retro_loading: false });
    });
    fetchRetrosheetPitcher(retrosheets_id).then(retro_pitching_stats => {
      this.setState({ retro_pitching_stats, retro_pitching_loading: false });
    });
    fetchCurrentStatistics(id).then(current_hitting_stats => {
      this.setState({
        current_hitting_stats: current_hitting_stats,
        current_hitting_loading: false,
      });
    });
    fetchCurrentPitchingStatistics(id).then(current_pitching_stats => {
      this.setState({
        current_pitching_stats: current_pitching_stats,
        current_pitching_loading: false,
      });
    });
  };

  statsTable = (stats, title, loading_title, loading, position_id) => {
    return (
      <Segment className="Player-Segment">
        <Dimmer active={loading} inverted>
          <Loader inverted>{loading_title}</Loader>
        </Dimmer>
        {stats && (
          <StatsTable title={title} stats={stats} pitcher={[12, 13, 17].includes(position_id)} />
        )}
      </Segment>
    );
  };

  render() {
    const {
      id,
      baseball_reference_id,
      first_name,
      last_name,
      mlb_id,
      position_id,
      razzball_id,
      retrosheets_id,
      team_id,
    } = this.state.player;

    return (
      <div>
        <Table className="Player-Table" unstackable striped size="small">
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={3}>
                {first_name} {last_name} ({this.getPosition(position_id)}) -{' '}
                {this.state.teams && this.getTeam(team_id)[0].abbreviation}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ID: {id}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                MLB ID: <a href={'http://m.mlb.com/player/' + mlb_id}>{mlb_id}</a>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Retrosheets ID: {this.createRetrosheetLink(retrosheets_id)}</Table.Cell>
              <Table.Cell>Razzball: {razzball_id}</Table.Cell>
              <Table.Cell>Baseball Reference: {baseball_reference_id}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {this.statsTable(
          this.state.retro_stats,
          'Retrosheet Hitting Statistics',
          'Fetching Retrosheet Hitting Data',
          this.state.retro_loading,
          position_id
        )}
        {[12, 13, 17].includes(position_id) &&
          this.statsTable(
            this.state.retro_pitching_stats,
            'Retrosheet Pitching Statistics',
            'Fetching Retrosheet Pitching Data',
            this.state.retro_pitching_loading,
            position_id
          )}
        {this.statsTable(
          this.state.current_hitting_stats,
          'Current Hitting Statistics',
          'Fetching Current Hitting Data',
          this.state.current_hitting_loading,
          position_id
        )}
        {[12, 13, 17].includes(position_id) &&
          this.statsTable(
            this.state.current_pitching_stats,
            'Current Pitching Statistics',
            'Fetching Current Pitching Data',
            this.state.current_pitching_loading,
            position_id
          )}
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Player);
