import 'semantic-ui-css/semantic.min.css';
import './App.css';
import React, { Component } from 'react';
import { Header, Icon, Menu, Sidebar } from 'semantic-ui-react';
import { BrowserRouter, Link, Route } from 'react-router-dom';
import { withAuthentication } from './components/Session';

import Games from './components/Games';
import Home from './components/Home';
import Metrics from './components/Metrics';
import Player from './components/Player';
import SimulationResults from './components/SimulationResults';
import RegressionResults from './components/RegressionResults';
import Teams from './components/teams';
import Team from './components/Team';
import Wagers from './components/Wager';
import LogoutButton from './components/Logout';
import LoginPage from './components/Login';
import AuthUserContext from './components/Session/context';
import SimulationProbabilities from './components/SimulationProbabilities';

class App extends Component {
  constructor(properties) {
    super(properties);

    this.state = {
      visible: false,
    };
  }

  toggleMenu = () => this.setState({ visible: !this.state.visible });

  render() {
    const { visible } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => {
          return (
            <Sidebar.Pushable>
              <Sidebar
                as={Menu}
                animation="overlay"
                icon="labeled"
                inverted
                onHide={this.toggleMenu}
                vertical
                visible={visible}
                width="thin"
                className={'h-100'}
              >
                <Menu.Item>
                  <a href={'/'}>
                    <Icon name="home" className={'mr-1'} />
                    <>Home</>
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href={'/games'}>
                    <Icon name="baseball ball" className={'mr-1'} />
                    Games
                  </a>
                </Menu.Item>

                <Menu.Item>
                  <a href={'/wagers'}>
                    <Icon name="money" className={'mr-1'} />
                    Wagers
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <a href={'/wager/metrics'}>
                    <Icon name="line graph" className={'mr-1'} />
                    Metrics
                  </a>
                </Menu.Item>
                <LogoutButton authUser={authUser} />
                {!authUser && (
                  <Menu.Item>
                    <a href={'/login'}>
                      <Icon name="sign in" className={'mr-1'} />
                      Log in
                    </a>
                  </Menu.Item>
                )}
              </Sidebar>

              <Sidebar.Pusher>
                <BrowserRouter>
                  <div className="App">
                    <Icon
                      size="large"
                      className={'Menu-Button'}
                      name={'bars'}
                      onClick={this.toggleMenu}
                    />
                    <header className="App-header">
                      <Link to={'/'}>
                        <Header as="h1" className="App-title">
                          <Icon name="money bill alternate outline" />
                          BaseballSim.Bet
                        </Header>
                      </Link>
                    </header>
                    <Route path="/" exact component={Home} />
                    <Route path="/games" exact component={Games} />
                    <Route path="/team/:id" exact component={Team} />
                    <Route path="/player/:id" exact component={Player} />
                    <Route path="/regression_results" exact component={RegressionResults} />
                    <Route
                      path="/simulation_results/:game_id"
                      exact
                      component={SimulationResults}
                    />
                    <Route path="/teams" exact component={Teams} />
                    <Route path="/wagers/:date" exact component={Wagers} />
                    <Route path="/wagers" exact component={Wagers} />
                    <Route path="/wager/metrics" exact component={Metrics} />
                    <Route path="/login" exact component={LoginPage} />
                    <Route
                      path="/simulation_probabilities/:gameId"
                      exact
                      component={SimulationProbabilities}
                    />
                  </div>
                </BrowserRouter>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          );
        }}
      </AuthUserContext.Consumer>
    );
  }
}

export default withAuthentication(App);

// todo filter team games on team page by year
