import React, { Component } from 'react';
import { calculateExpectedValue } from '../../utilities/helpers';
import { Statistic, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class ExpectedValueTable extends Component {
  bufferCell = (buffer, odds) => {
    if (buffer !== odds) return buffer;

    return (
      <Statistic size="mini" color="black">
        <Statistic.Value>{buffer}</Statistic.Value>
      </Statistic>
    );
  };

  evCell = (buffer, odds, winPercentage) => {
    const event_ = calculateExpectedValue(buffer, winPercentage).toFixed(2);

    const color = event_ > 5 ? 'green' : 'black';

    if (event_ > 5 || buffer === odds) {
      return (
        <Statistic size="mini" color={color}>
          <Statistic.Value>{event_}</Statistic.Value>
        </Statistic>
      );
    }

    return event_;
  };

  outOfBounds = buffer => buffer > -100 && buffer < 100;

  oddsAndEvBuffers = () => {
    const { homeOdds, awayOdds, homeWinPercentage, awayWinPercentage } = this.props;

    return [-10, -5, 0, 5, 10].map(buffer => {
      const homeBuffer = homeOdds + buffer * -1;
      const awayBuffer = awayOdds + buffer;

      // eslint-disable-next-line unicorn/no-null
      if (this.outOfBounds(awayBuffer) || this.outOfBounds(homeBuffer)) return null;

      return (
        <Table.Row key={buffer}>
          <Table.Cell>{this.bufferCell(awayBuffer, awayOdds)}</Table.Cell>
          <Table.Cell>{this.evCell(awayBuffer, awayOdds, awayWinPercentage)}</Table.Cell>
          <Table.Cell>{this.bufferCell(homeBuffer, homeOdds)}</Table.Cell>
          <Table.Cell>{this.evCell(homeBuffer, homeOdds, homeWinPercentage)}</Table.Cell>
        </Table.Row>
      );
    });
  };

  render() {
    const { homeAbbreviation, awayAbbreviation } = this.props;

    return (
      <Table className="Odds-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={4}>Expected Value</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>{awayAbbreviation}</Table.HeaderCell>
            <Table.HeaderCell>EV</Table.HeaderCell>
            <Table.HeaderCell>{homeAbbreviation}</Table.HeaderCell>
            <Table.HeaderCell>EV</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.oddsAndEvBuffers()}</Table.Body>
      </Table>
    );
  }
}

ExpectedValueTable.propTypes = {
  awayAbbreviation: PropTypes.string.isRequired,
  homeAbbreviation: PropTypes.string.isRequired,
  homeOdds: PropTypes.number.isRequired,
  awayOdds: PropTypes.number.isRequired,
  homeWinPercentage: PropTypes.number.isRequired,
  awayWinPercentage: PropTypes.number.isRequired,
};

export default ExpectedValueTable;
