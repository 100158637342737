import React, { useState } from 'react';
import { Icon, Pagination } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const totalPages = (items, maxItemsPerPage) => {
  const totalPages = Math.ceil(items.length / maxItemsPerPage);

  return totalPages > 0 ? totalPages : 1;
};

export default function Paginator({ children, items, maxItemsPerPage }) {
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, { activePage }) => setActivePage(activePage);

  const renderPaginator = () => {
    if (items.length <= maxItemsPerPage) return;

    return (
      <Pagination
        floated={'right'}
        size={'mini'}
        defaultActivePage={activePage}
        onPageChange={handlePageChange}
        firstItem={undefined}
        lastItem={undefined}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
        totalPages={totalPages(items, maxItemsPerPage)}
      />
    );
  };

  const itemsOnThisPage = items.slice(
    (activePage - 1) * maxItemsPerPage,
    maxItemsPerPage * activePage
  );

  return children({ itemsOnThisPage, renderPaginator, activePage });
}

Paginator.defaultProps = {
  domain: undefined,
};

Paginator.propTypes = {
  children: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
  maxItemsPerPage: PropTypes.number.isRequired,
  domain: PropTypes.any,
};
