import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown, Header, Icon, Loader, Popup, Table } from 'semantic-ui-react';
import {
  fetchTeam,
  fetchGamesByTeam,
  fetchTeams,
  fetchWagersAfterDateBeforeDate,
} from '../../utilities/api';
import './team.css';
import { withAuthorization } from '../Session';

const addPlusSign = value => (value > 0 ? `+${value}` : value);

const formatTime = date => new Date(date).toLocaleDateString();

const totalResult = wagers =>
  wagers
    .map(({ unit, outcome }) => outcome / unit)
    // eslint-disable-next-line unicorn/no-reduce
    .reduce((accumulator, value) => Number.parseFloat(accumulator) + Number.parseFloat(value));

const Team = () => {
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [wagers, setWagers] = useState([]);
  const [teamInfo, setTeamInfo] = useState({});
  const [year, setYear] = useState(new Date().getFullYear());
  const [againstWagers, setAgainstWagers] = useState([]);
  const [forWagers, setForWagers] = useState([]);

  const { id: teamId } = useParams();

  useEffect(() => {
    setLoading(true);
    const startDate = `${year}0101`;
    const endDate = `${year + 1}0101`;

    fetchTeam(teamId).then(teamInfo => setTeamInfo(teamInfo));
    fetchTeams().then(teams => setTeams(teams));
    fetchWagersAfterDateBeforeDate(startDate, endDate)
      .then(wagers => {
        setWagers(wagers);
      })
      .finally(() => setLoading(false));
    fetchGamesByTeam(teamId).then(games => setGames(games));
  }, [teamId, year]);

  useEffect(() => {
    const gameIds = new Set(games.map(game => game.id));

    setForWagers(wagers.filter(wager => wager.team_id === Number.parseInt(teamId, 10)));
    setAgainstWagers(
      wagers
        .filter(wager => wager.team_id !== Number.parseInt(teamId, 10))
        .filter(({ game_id }) => gameIds.has(game_id))
    );
  }, [games, teamId, wagers]);

  const againstWins = againstWagers.filter(({ outcome }) => outcome > 0).length;

  const againstLosses = againstWagers.length - againstWins;

  const forWins = forWagers.filter(({ outcome }) => outcome > 0).length;

  const forLosses = forWagers.length - forWins;

  const yearChange = (event, inputData) => setYear(inputData.value);

  const getGame = game_id => {
    const { away_id, home_id } = games.find(game => game.id === game_id);
    const [{ abbreviation: homeAbbreviation }] = getTeam(home_id);
    const [{ abbreviation: awayAbbreviation }] = getTeam(away_id);

    return `${awayAbbreviation}@${homeAbbreviation}`;
  };

  const getTeam = team_id => teams.filter(team => team.id === team_id);

  const addTotalsToTable = wagers => {
    return (
      <Table.Row>
        <Table.Cell className={'t-left'} colSpan={4}>
          <strong>Totals</strong>
        </Table.Cell>
        <Table.Cell>
          <strong>{wagers.length > 0 ? addPlusSign(totalResult(wagers).toFixed(2)) : ''}</strong>
        </Table.Cell>
      </Table.Row>
    );
  };

  const addWagersToTable = wagers =>
    wagers.map(wager => {
      const { game_id, game_date_time, game_of_day, id, line, outcome, unit } = wager;

      return (
        <Table.Row key={id}>
          <Table.Cell>{wager.id}</Table.Cell>
          <Table.Cell>{formatTime(game_date_time)}</Table.Cell>
          <Table.Cell>
            {game_id && games && <a href={`/simulation_results/${game_id}`}>{getGame(game_id)}</a>}
            {wager && game_of_day === 1 && (
              <Popup
                trigger={<Icon name="lightning" color="yellow" />}
                content={'Game of the Day'}
              />
            )}
          </Table.Cell>
          <Table.Cell>{line > 0 ? `+${line}` : line}</Table.Cell>
          <Table.Cell>{addPlusSign((outcome / unit).toFixed(2))}</Table.Cell>
        </Table.Row>
      );
    });

  if (games.length === 0 || !teamId || loading)
    return <Loader active={true} content={'Loading Team Data...'} size={'massive'} />;

  return (
    <div>
      <Header as="h2" className="Results-Header">
        <Icon name="bar chart" />
        <Header.Content>
          {teamInfo?.city} {teamInfo?.mascot}
          <Header.Subheader>
            <Dropdown
              options={[...new Array(new Date().getFullYear() - 2015)]
                .map((_, key) => new Date().getFullYear() - key)
                .map((y, key) => ({
                  key,
                  text: y,
                  value: y,
                }))}
              name={'year'}
              inline
              value={year}
              onChange={yearChange}
              className={'mt-2'}
            />{' '}
            Results
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Table className="Team-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="7" className="Team-Header">
              Betting On ({forWagers && `${forWins}-${forLosses}`})
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Wager ID</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Match-up</Table.HeaderCell>
            <Table.HeaderCell>Line</Table.HeaderCell>
            <Table.HeaderCell>Result (Units)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {forWagers && addWagersToTable(forWagers)}
          {forWagers && addTotalsToTable(forWagers)}
        </Table.Body>
      </Table>

      <Table className="Team-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="7" className="Team-Header">
              Betting Against ({againstWagers && `${againstWins}-${againstLosses}`})
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Wager ID</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Match-Up</Table.HeaderCell>
            <Table.HeaderCell>Line</Table.HeaderCell>
            <Table.HeaderCell>Result (Units)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {againstWagers && addWagersToTable(againstWagers)}
          {againstWagers && addTotalsToTable(againstWagers)}
        </Table.Body>
      </Table>
    </div>
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Team);
