import React, { useEffect, useState } from 'react';
import { Icon, Popup, Card, Grid, Loader } from 'semantic-ui-react';
import { fetchGameByDate, fetchTeams } from '../../utilities/api';
import './games.css';
import { withAuthorization } from '../Session';
import Calendar from '../calendar';
import { toDateQueryString, formatBettingLine } from '../../utilities/helpers';

const condition = authUser => !!authUser;

const formatGameTime = date => {
  let hour = Number(date.slice(11, 13));

  hour = hour === 12 ? hour : hour % 12;
  const minutes = date.slice(14, 16);
  const amPM = Number(date.slice(11, 13)) >= 12 ? 'PM' : 'AM';

  return `${hour}:${minutes} ${amPM}`;
};

const formatSp = sp => {
  const { first_name: first, last_name: last } = sp;

  return `${first[0].slice(0, 1)}. ${last}`;
};

const Games = () => {
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [gameDate, setGameDate] = useState(toDateQueryString(new Date()));

  useEffect(() => {
    fetchTeams().then(teams => setTeams(teams));
    fetchGameByDate(gameDate).then(games =>
      setGames(
        games
          .sort((a, b) => (a.game_date_time > b.game_date_time ? -1 : 1))
          .sort(({ postponed }, _) => (postponed === 1 ? 1 : -1))
          .sort((a, _) => (a.wagers.length > 0 ? -1 : 0))
      )
    );
  }, [gameDate]);

  const getTeamAbbrev = teamId =>
    teams.map(({ id, abbreviation }) => (id === teamId ? abbreviation : undefined));

  const gameRows = () => {
    return games.map(game => {
      const {
        away_id: awayId,
        game_date_time: gameDateTime,
        events_parsed: eventsParsed,
        home_id: homeId,
        id,
        postponed,
        simmed,
        wagers,
        lineup,
        odds,
        probable_away_sp: awaySp = {},
        probable_home_sp: homeSp = {},
      } = game;

      const replacement_pitchers = lineup?.replacement_pitchers?.length > 0;

      const { away_line: awayLine = '', home_line: homeLine = '', total } = odds[0] || {};

      const away = getTeamAbbrev(awayId);
      const home = getTeamAbbrev(homeId);

      return (
        <Card key={id} fluid raised color={'yellow'}>
          <Card.Content>
            <Card.Header>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column className={'t-left'} width={3}>
                    {simmed === 1 && (
                      <a href={`/simulation_results/${game.id}`}>
                        <Icon
                          name="newspaper outline"
                          color={replacement_pitchers ? 'red' : 'green'}
                          size={'large'}
                        />
                      </a>
                    )}
                    {postponed === 1 && (
                      <Popup
                        trigger={<Icon color={'red'} name={'hourglass outline'} size={'large'} />}
                        content={'Postponed'}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column className={'t-center'} width={10}>
                    <Popup
                      trigger={
                        <span className={'m-auto slate-gray'}>
                          {away} @ {home}
                        </span>
                      }
                      content={
                        <span>
                          {id}
                          {eventsParsed === 1 && (
                            <Icon color="green" className={'ml-1'} name={'checkmark'} />
                          )}
                        </span>
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={3} className={'t-right'}>
                    {wagers.length > 0 && (
                      <Popup
                        trigger={<Icon color={'green'} name={'dollar sign'} size={'large'} />}
                        content={'In play!'}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Header>
          </Card.Content>
          <Card.Content className={'t-center'}>
            <Card.Description>
              {awaySp && <span className={'ml-1'}>{formatSp(awaySp)}</span>}
              {awayLine && <span className={'ml-1'}>{formatBettingLine(awayLine)}</span>}
              <span className={'ml-1'}>@</span>
              {homeSp && <span className={'ml-1'}>{formatSp(homeSp)}</span>}
              {homeLine && <span className={'ml-1'}>{formatBettingLine(homeLine)}</span>}
              {total && <div className={'ml-1 mt-2'}>o/u {total}</div>}
            </Card.Description>
          </Card.Content>
          <Card.Content extra className={'t-center'}>
            <Icon name="clock" />
            {formatGameTime(gameDateTime)}
          </Card.Content>
        </Card>
      );
    });
  };

  if (teams.length === 0)
    return <Loader active={true} content={'Loading Games...'} size={'massive'} />;

  return (
    <div className={'m-auto Games-Container'}>
      <h2 className={'BaseballSim-Header'}>MLB Schedule</h2>
      <Calendar date={gameDate} onChange={(_, data) => setGameDate(data.value)} />
      {games.length > 0 && gameRows(games)}
      {games.length < 1 && <div>No games found for this day</div>}
      <span className={'mb-3'} />
    </div>
  );
};

export default withAuthorization(condition)(Games);
