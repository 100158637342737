const calculateExpectedValue = (line, win_percentage) => {
  win_percentage = win_percentage / 100;
  line = Number.parseInt(line, 10);

  let money_won = line;
  let money_lost = 100;

  if (line < 0) {
    money_lost = Math.abs(line);
    money_won = 100;
  }

  return win_percentage * money_won - (1 - win_percentage) * money_lost;
};

const toDateQueryString = date => date.toLocaleDateString('en-CA').replace(/-/g, '');

const formatBettingLine = line => (Number(line) < 0 ? line : `+${line}`);

export { calculateExpectedValue, formatBettingLine, toDateQueryString };
