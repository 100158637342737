import React, { useMemo, useEffect, useState } from 'react';
import { Divider, Header, Icon, Message, Table } from 'semantic-ui-react';
import Paginator from './paginator';
import { startCase } from 'lodash/fp';
import DataTableLabels from './data-labels';
import DataTableFilters from './data-filters';

const DIRECTIONS = {
  ASC: 'ascending',
  DESC: 'descending',
};

const compareValues = (key, order = DIRECTIONS.ASC) => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

    const sortKeyA = getSortValue(a[key]);
    const sortKeyB = getSortValue(b[key]);

    let comparison = 0;

    if (sortKeyA > sortKeyB) {
      comparison = 1;
    } else if (sortKeyA < sortKeyB) {
      comparison = -1;
    }
    return order === DIRECTIONS.DESC ? comparison * -1 : comparison;
  };
};

const getSortValue = value => {
  const sortKeyA = value?.sortKey || value;

  return typeof sortKeyA === 'string' ? sortKeyA.toUpperCase() : sortKeyA;
};

/**
 * One table to rule them all...maybe
 *
 * @param data - array - Data objects to populate table
 * @param header - str - Table header
 * @param showFilters - bool - Include dropdown filters
 * @param highlightRows - bool
 * @param maxItemsPerPage - int
 * @param excludeFromFilters - array - If showFilters exclude dropdown for these columns
 * @param DateFilters
 * @param tableProperties - object - Additional Semantic UI Table properties
 * @returns {JSX.Element}
 * @constructor
 */
export default function DataTable({
  data,
  header,
  showFilters = false,
  highlightRows = true,
  maxItemsPerPage = 50,
  excludeFromFilters = [],
  DateFilters,
  ...tableProperties
}) {
  const [sortDirection, setSortDirection] = useState(DIRECTIONS.ASC);
  const [sortColumn, setSortColumn] = useState('');
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const headers = tableData.length > 0 ? Object.keys(tableData[0]) : [];

  const changeSort = (newSortDirection, newSortColumn) => {
    setSortDirection(newSortDirection);
    setSortColumn(newSortColumn);
    setTableData(tableData.slice().sort(compareValues(newSortColumn, newSortDirection)));
  };

  console.log({ tableData });

  const filteredData = useMemo(() => {
    if (Object.keys(filters).length === 0) return tableData;

    return tableData.filter(
      datum =>
        Object.keys(datum).filter(dataKey =>
          filters.hasOwnProperty(dataKey)
            ? filters[dataKey].includes(datum[dataKey]?.filterKey || datum[dataKey])
            : false
        ).length === Object.keys(filters).length
    );
  }, [tableData, filters]);

  return (
    <>
      {header && (
        <>
          <Header textAlign={'center'} as={'h5'}>
            {header}
          </Header>
          <Divider />
        </>
      )}
      <div>
        <div className={'flex jc-between va-top'}>
          {showFilters && (
            <div className={'border-dashed w-100 min-h-3-3r p-1'}>
              <DataTableLabels labelList={filters} callback={setFilters} />
            </div>
          )}
          {DateFilters && (
            <div className={'ml-2 ws-nowrap'}>
              <DateFilters />
            </div>
          )}
        </div>
        {filteredData.length === 0 ? (
          <Message>
            <Icon name={'exclamation circle'} />
            No data found
          </Message>
        ) : (
          <Paginator
            maxItemsPerPage={maxItemsPerPage}
            items={filteredData}
            domain={filteredData.length}
          >
            {({ itemsOnThisPage, renderPaginator }) => (
              <div className={'mt-2 mb-2'}>
                <Table compact={'very'} striped sortable celled size={'small'} {...tableProperties}>
                  <Table.Header>
                    <Table.Row>
                      {headers
                        .filter(header => !['positive', 'negative', 'warning'].includes(header))
                        .map((header, key) => (
                          <Table.HeaderCell
                            sorted={sortColumn === header ? sortDirection : undefined}
                            onClick={() =>
                              changeSort(
                                sortDirection === DIRECTIONS.ASC ? DIRECTIONS.DESC : DIRECTIONS.ASC,
                                header
                              )
                            }
                            key={key}
                            className={'fs-1 t-center'}
                          >
                            {startCase(header)}
                          </Table.HeaderCell>
                        ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body className={highlightRows ? 'tr-hover' : ''}>
                    {showFilters && (
                      <Table.Row>
                        {headers.map((header, key) => {
                          if (excludeFromFilters.includes(header)) return <Table.Cell key={key} />;

                          return (
                            <Table.Cell key={key} className={'t-center'}>
                              <DataTableFilters
                                callback={setFilters}
                                name={header}
                                options={tableData}
                                filters={filters}
                              />
                            </Table.Cell>
                          );
                        })}
                      </Table.Row>
                    )}
                    {itemsOnThisPage.map(
                      ({ positive = false, negative = false, warning = false, ...row }, index) => (
                        <Table.Row
                          key={index}
                          positive={positive}
                          negative={negative}
                          warning={warning}
                        >
                          {Object.values(row).map((cell, index) => (
                            <Table.Cell key={index}>{cell?.value || cell}</Table.Cell>
                          ))}
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                  {headers.length > 0 && (
                    <Table.Footer fullWidth>
                      <Table.Row className={'h-3'}>
                        <Table.HeaderCell colSpan={headers.length}>
                          {renderPaginator()}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  )}
                </Table>
              </div>
            )}
          </Paginator>
        )}
      </div>
    </>
  );
}
