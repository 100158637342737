import React, { Component } from 'react';
import { Table, Progress, Popup, Label, Header, Icon } from 'semantic-ui-react';
import { fetchGamesByYear, fetchRegressionResults } from '../../utilities/api';
import './regressionResults.css';
import { withAuthorization } from '../Session';

class Metrics extends Component {
  state = { regressionResults: [], totalGames: 0 };

  componentDidMount = () => this.fetchData();

  fetchData = async () => {
    const year = 2019;
    const { regressionResults } = this.state;

    fetchGamesByYear(year).then(games => this.setState({ totalGames: games.length }));

    // const r1 = await fetchRegressionResults('http://45.79.88.127', 'v1');
    const r2 = await fetchRegressionResults('http://23.239.0.180', 'v2');
    const r3 = await fetchRegressionResults('http://104.237.155.158', 'v2');
    const r4 = await fetchRegressionResults('http://45.33.46.89', 'v2');
    const r5 = await fetchRegressionResults('http://45.79.66.30', 'v2');

    this.setState({
      regressionResults: [
        ...regressionResults,
        // {
        //   url: 'http://45.79.88.127',
        //   results: r1,
        //   title: '2019 Re-run',
        //   version: 'v1',
        // },
        {
          url: 'http://23.239.0.180',
          results: r2,
          title: 'RP - Mix h:40/c:50/m:10',
          version: 'v2',
        },
        {
          url: 'http://104.237.155.158',
          results: r3,
          title: 'Mix h:40/c:50/m:10',
          version: 'v2',
        },
        {
          url: 'http://45.33.46.89',
          results: r4,
          title: 'Mix h:34/c:33/m:33',
          version: 'v2',
        },
        {
          url: 'http://45.79.66.30',
          results: r5,
          title: 'V2 - Relief Pitching',
          version: 'v2',
        },
      ],
    });
  };

  firstSimTime = regResults => {
    let oldest = new Date();

    regResults.map(({ date_time, createdAt }) => {
      const date = date_time ? date_time : createdAt;
      if (new Date(date).getTime() < oldest.getTime()) oldest = new Date(date);

      return oldest;
    });

    return oldest;
  };

  lastSimTime = regResults => {
    let mostRecent = new Date('2010-01-01T21:27:10.000Z');

    regResults.map(({ date_time, createdAt }) => {
      const date = date_time ? date_time : createdAt;

      if (new Date(date).getTime() > mostRecent.getTime()) mostRecent = new Date(date);

      return mostRecent;
    });

    return mostRecent;
  };

  generateStatsTable = regResults => {
    const { totalGames } = this.state;

    return (
      <Table
        className="RegResults-AccountsTable"
        unstackable
        striped
        textAlign="center"
        size="small"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Progress</Table.HeaderCell>
            <Table.HeaderCell>Per Sim (min)</Table.HeaderCell>
            <Table.HeaderCell>Time Remaining (hr)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {regResults.map(({ url, title, results, version }) => {
            const lastSimTime = this.lastSimTime(results);
            const firstSimTime = this.firstSimTime(results);
            const elapsedTime = (lastSimTime.getTime() - firstSimTime.getTime()) / (1000 * 60);
            const minutesPerSim = elapsedTime / results.length;

            const gamesRemaining = totalGames - results.length;
            const timeRemaining = (gamesRemaining * minutesPerSim) / 60;
            const percent = (results.length / totalGames) * 100;

            return (
              <Table.Row>
                <Table.Cell>
                  <Popup
                    trigger={
                      <a href={`${url}/api/${version}/simulation_results`}>
                        <Label>{title}</Label>
                      </a>
                    }
                    content={<a href={`${url}/api/${version}/simulation_results`}>{url}</a>}
                    position="top left"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Popup
                    trigger={
                      <Progress percent={percent} indicating size={'small'}>
                        {percent.toFixed(2)}%
                      </Progress>
                    }
                    content={
                      <div>
                        <p>
                          Last Completed: {lastSimTime.toLocaleDateString()} -{' '}
                          {lastSimTime.toLocaleTimeString()}
                        </p>
                        <p>
                          Simmed: {results.length} / {totalGames}
                        </p>
                      </div>
                    }
                    position="top left"
                  />
                </Table.Cell>
                <Table.Cell>{minutesPerSim.toFixed(2)}</Table.Cell>
                <Table.Cell>{timeRemaining.toFixed(2)}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  percentage = (wins, losses) =>
    (Number.parseFloat(wins + losses) > 0
      ? (wins / Number.parseFloat(wins + losses)) * 100
      : 0
    ).toFixed(2);

  render() {
    const { regressionResults } = this.state;

    return (
      <div>
        <Header as="h2" className="RegressionResults-Header">
          <Icon name="flask" />
          <Header.Content>Regression Testing</Header.Content>
        </Header>
        {regressionResults.length > 0 && this.generateStatsTable(regressionResults)}
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Metrics);
