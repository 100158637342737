import React, { Component } from 'react';
import { Message, Popup, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class LineupsTable extends Component {
  getClassName = (replacements, playerId) =>
    this.isReplacement(replacements, playerId) ? 'red' : '';

  isReplacement = (replacements, playerId) =>
    !!(replacements.map(({ id }) => id).includes(playerId) || playerId === 100001);

  resolveReplacementCode = code => {
    const reasons = {
      1: 'Player not found',
      2: 'Player has not met minimum PA',
      3: 'Player has not met minimum IP',
    };

    return reasons[code];
  };

  printPlayer = player => {
    const {
      id,
      first_name: firstName,
      last_name: lastName,
      position: { position = '' } = {},
    } = player;

    const { replacementHitters } = this.props;

    let playerString = `${firstName} ${lastName}`;

    if (firstName !== 'Replacement') {
      playerString += ` (${position})`;
    }

    if (this.isReplacement(replacementHitters, id)) {
      const { code } = replacementHitters.find(({ id: repId }) => repId === player.id);

      return (
        <Popup
          trigger={<p>{playerString}</p>}
          content={this.resolveReplacementCode(code)}
          position={'top center'}
        />
      );
    }

    return playerString;
  };

  hitterCell = player => {
    const { replacementHitters } = this.props;

    return (
      <Table.Cell className={this.getClassName(replacementHitters, player.id)}>
        {this.printPlayer(player)}
      </Table.Cell>
    );
  };

  startingPitcherCell = (teamAbbreviation, startingPitcher) => {
    // eslint-disable-next-line unicorn/no-null
    if (!startingPitcher) return null;

    const { replacementPitchers } = this.props;

    const { id = 0, first_name: firstName = '', last_name: lastName = '' } = startingPitcher;

    const className = this.getClassName(replacementPitchers, id);

    return (
      <Table.HeaderCell className={className}>
        {teamAbbreviation}
        {` (${firstName.slice(0, 1)}.${lastName})`}
      </Table.HeaderCell>
    );
  };

  renderLineups = () => {
    const { awayLineup, homeLineup } = this.props;

    return [0, 1, 2, 3, 4, 5, 6, 7, 8].map(battingSpot => {
      return (
        <Table.Row key={battingSpot}>
          {this.hitterCell(awayLineup[battingSpot])}
          {this.hitterCell(homeLineup[battingSpot])}
        </Table.Row>
      );
    });
  };

  render() {
    const {
      awayAbbreviation,
      homeAbbreviation,
      awayLineup,
      homeLineup,
      awaySp,
      homeSp,
    } = this.props;

    if (homeLineup.length !== 9 || awayLineup.length !== 9) {
      return (
        <Table className="Lineups-Table" unstackable striped textAlign="center" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="alert-warning">
                <Message warning>No lineups found for this game</Message>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        </Table>
      );
    }

    return (
      <Table className="Lineups-Table" unstackable striped textAlign="center" size="small">
        <Table.Header>
          <Table.Row>
            {this.startingPitcherCell(awayAbbreviation, awaySp)}
            {this.startingPitcherCell(homeAbbreviation, homeSp)}
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderLineups()}</Table.Body>
      </Table>
    );
  }
}

LineupsTable.propTypes = {
  awayAbbreviation: PropTypes.string.isRequired,
  homeAbbreviation: PropTypes.string.isRequired,
  awayLineup: PropTypes.array.isRequired,
  homeLineup: PropTypes.array.isRequired,
  awaySp: PropTypes.object.isRequired,
  homeSp: PropTypes.object.isRequired,
  replacementPitchers: PropTypes.array.isRequired,
  replacementHitters: PropTypes.array.isRequired,
};

export default LineupsTable;
