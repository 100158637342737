import React, { Component } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Icon, Responsive } from 'semantic-ui-react';

class Calendar extends Component {
  renderDateInput = (inline) => {
    const { date, onChange } = this.props;

    return (
      <DateInput
        inline={inline}
        iconPosition={'right'}
        dateFormat={'YYYYMMDD'}
        name={'wagerDate'}
        placeholder={date}
        value={date || ''}
        popupPosition="bottom left"
        onChange={onChange}
        clearable={true}
        closable={true}
        closeOnMouseLeave={false}
        className={'Calendar-Date-Input'}
        animation={'none'}
      />
    );
  };

  render() {
    return (
      <div className={'Calendar-Input-Wrapper'}>
        <Responsive minWidth={600}>
          <Icon size={'large'} name={'calendar alternate outline'} />
          {this.renderDateInput(false)}
        </Responsive>
        <Responsive maxWidth={600}>{this.renderDateInput(true)}</Responsive>
      </div>
    );
  }
}

export default Calendar;
